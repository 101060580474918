import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
// eslint-disable-next-line import/no-unresolved
import NewItem from '@/views/NewItem.vue';
// eslint-disable-next-line import/no-unresolved
import TabsPage from '@/views/TabsPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/folder/Inbox',
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1',
      },
      {
        path: 'tab1',
        component: () => import('../views/Participant/Index.vue'),
      },
      {
        path: 'tab2',
        component: () => import('../views/Participant/Index.vue'),
      },
      {
        path: 'tab3',
        component: () => import('../views/Participant/Index.vue'),
      },
    ],
  },
  {
    path: '/folder/:id',
    component: () => import('../views/FolderPage.vue'),
  },
  {
    path: '/participants',
    component: () => import('../views/Participant/Index.vue'),
  },
  {
    path: '/participants/:id',
    component: () => import('../views/Participant/Show.vue'),
  },
  {
    path: '/participants/:id/edit',
    component: () => import('../views/Participant/Edit.vue'),
  },
  {
    path: '/new',
    name: 'NewItem',
    component: NewItem,
  },
  {
    path: '/show/:id',
    name: 'Show',
    component: () => import('../components/Show.vue'),
  },
  {
    path: '/edit/:id',
    name: 'Edit',
    component: () => import('../components/Edit.vue'),
  },
  {
    path: '/add',
    name: 'Add',
    component: () => import('../components/Add.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
